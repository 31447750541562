import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Slider from "../components/slider"
import Seo from "../components/seo"
import ReactLinkify from "react-linkify"
import PostList from "../components/post_list_alpha"

const MisterJius = ({ data }) => {

  const slider_data = data.allMdx.nodes.map((x) =>  x);

  const first_video = slider_data.shift();

  const metaData = [{
    name: "keywords",
    content: first_video.frontmatter.keywords,
  }];

  const description = "Watch me cook recipes from Brandon Jew and Tienlon Ho's Mister Jiu's in Chinatown cookbook, which includes recipes for Chinese American restaurant food.";

  const image = getImage(first_video.fields.thumbnail);

  return (
    <Layout>
      <Seo title="Mister Jiu's in Chinatown" image={image} meta={metaData} description={description} />
      <h1>Mister Jiu's in Chinatown</h1>
      <h2>Recipes and Stories from the Birthplace of Chinese American Food</h2>
      <p>
        This book includes recipes for many Chinese American classics, likely in their fanciest 
        forms, as made in the Michelin-starred restaurant's kitchen. It is gorgeous, filled with
        beautiful photos of the food as well as San Francisco's Chinatown in the past and present. 
      </p>
      <p>
        <a href="https://www.instagram.com/brandoj/?hl=en">Brandon Jew</a> is the executive chef
        and owner of Mister Jiu's restaurant in San Francisco's Chinatown.
      </p>
      <p>
        <a href="https://tienlon.com/">Tienlon Ho</a>, co-author, is a Chinese American food writer,
        and also an attorney.
      </p>
      {first_video && (
        <div className="featured-video">
          <h2>{first_video.frontmatter.title}</h2>
          <Link to={first_video.fields.slug}>
            {first_video.fields.thumbnail && 
              <GatsbyImage image={getImage(first_video.fields.thumbnail)} alt={first_video.frontmatter.title + ' from ' + first_video.frontmatter.source}/> 
            }
          </Link>
          <figcaption>
            {first_video.frontmatter.description && (
              <ReactLinkify>{first_video.frontmatter.description.split('\n').shift()}</ReactLinkify>
            )}
          </figcaption>
        </div>
      )}
      {slider_data.length > 0 &&  (
      <div className="video-slider">
        <h2>More Recent Videos</h2>
        <Slider data={slider_data} />
      </div>)}
      <div className="all-videos">
        <h2>All Videos</h2>
        <PostList data={data.allMdx.nodes} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMdx(
      filter: { frontmatter: { keywords: { regex: "/jiu/" } } }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      nodes {
        fields {
          youtubeId
          slug
          thumbnail {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH)
            }
          }
        }
        frontmatter {
          title
          source
          date(formatString: "MMMM D, Y")
          video_url
          description
          keywords
        }
      }
    }
  }
`

export default MisterJius;